import moment from 'moment'

export function sortAscending(data, comparator) {
    return data.slice().sort((a, b) => {
        if (a[comparator] < b[comparator]) {
            return -1
        }
        if (a[comparator] > b[comparator]) {
            return 1
        }
        return 0
    })
}

export function sortDescending(data, comparator) {
    return data.slice().sort((a, b) => {
        if (a[comparator] < b[comparator]) {
            return 1
        }
        if (a[comparator] > b[comparator]) {
            return -1
        }
        return 0
    })
}

export function sortAscendingBh(data, comparator) {
    return data.slice().sort((a, b) => {
        let prevPatientData = ''
        let postPatientData = ''
        if (comparator === 'patient') {
            prevPatientData = a['patient']['first_name']
            postPatientData = b['patient']['first_name']
        } else if (comparator === 'properties') {
            prevPatientData =
                a['properties']['appointment'][
                    'speciality'
                ]['name']
            postPatientData =
                b['properties']['appointment'][
                    'speciality'
                ]['name']
        } else if (comparator === 'createdAt') {
            prevPatientData =
                a['properties']['appointment']['date']
            postPatientData =
                b['properties']['appointment']['date']
        } else if (comparator === 'provider') {
            prevPatientData = a['provider']['first_name']
            postPatientData = b['provider']['first_name']
        } else {
            prevPatientData = a[comparator]
            postPatientData = b[comparator]
        }
        if (prevPatientData < postPatientData) {
            return -1
        }
        if (prevPatientData > postPatientData) {
            return 1
        }
        return 0
    })
}

export function sortDescendingBh(data, comparator) {
    return data.slice().sort((a, b) => {
        let prevPatientData = ''
        let postPatientData = ''
        if (comparator === 'patient') {
            prevPatientData = a['patient']['first_name']
            postPatientData = b['patient']['first_name']
        } else if (comparator === 'properties') {
            prevPatientData =
                a['properties']['appointment'][
                    'speciality'
                ]['name']
            postPatientData =
                b['properties']['appointment'][
                    'speciality'
                ]['name']
        } else if (comparator === 'createdAt') {
            prevPatientData =
                a['properties']['appointment']['date']
            postPatientData =
                b['properties']['appointment']['date']
        } else if (comparator === 'provider') {
            prevPatientData = a['provider']['first_name']
            postPatientData = b['provider']['first_name']
        } else {
            prevPatientData = a[comparator]
            postPatientData = b[comparator]
        }
        if (prevPatientData < postPatientData) {
            return 1
        }
        if (prevPatientData > postPatientData) {
            return -1
        }
        return 0
    })
}

export function getTimeframe(timeframe, interval) {
    if (timeframe === 'today') {
        return {
            fromDate: moment().format('MM-DD-YYYY'),
            toDate: moment().format('MM-DD-YYYY'),
        }
    } else if (timeframe === 'weekly') {
        if (interval === 'past') {
            return {
                fromDate: moment()
                    .subtract(1, 'week')
                    .format('MM-DD-YYYY'),
                toDate: moment().format('MM-DD-YYYY'),
            }
        } else {
            return {
                fromDate: moment().format('MM-DD-YYYY'),
                toDate: moment()
                    .add(1, 'week')
                    .format('MM-DD-YYYY'),
            }
        }
    } else if (timeframe === 'monthly') {
        if (interval === 'past') {
            return {
                fromDate: moment()
                    .subtract(1, 'month')
                    .format('MM-DD-YYYY'),
                toDate: moment().format('MM-DD-YYYY'),
            }
        } else {
            return {
                fromDate: moment().format('MM-DD-YYYY'),
                toDate: moment()
                    .add(1, 'month')
                    .format('MM-DD-YYYY'),
            }
        }
    } else if (timeframe === 'yearly') {
        if (interval === 'past') {
            return {
                fromDate: moment()
                    .subtract(1, 'year')
                    .format('MM-DD-YYYY'),
                toDate: moment().format('MM-DD-YYYY'),
            }
        } else {
            return {
                fromDate: moment().format('MM-DD-YYYY'),
                toDate: moment()
                    .add(1, 'year')
                    .format('MM-DD-YYYY'),
            }
        }
    }
}

export const phoneFormat = (str) => {
    //Filter only numbers from the input
    let cleaned = ('' + str).replace(/\D/g, '')

    //Check if the input is of correct length
    let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)

    if (match) {
        return (
            '(' +
            match[1] +
            ') ' +
            match[2] +
            '-' +
            match[3]
        )
    }

    return null
}

export function capitalizeFirstLetter(string = '') {
    return string
        .split(' ')
        .map((s) => s.charAt(0).toUpperCase() + s.slice(1))
        .join(' ')
}

export const isObjectNull = (obj = null) => {
    if(!obj) return true
    if(Object.keys(obj).length > 0) {
        return false
    }
    return true
}

export const objectReversal = (obj = null) => {
    if(!obj || isObjectNull(obj)) return 
    const reverseObject = {}
    Object.keys(obj).map((item) => {
        reverseObject[obj[item]] = parseInt(item) 
    })
    return reverseObject
}
