import React, { useEffect, useRef, useState } from 'react'
import './appointmentNew.scss'
import { Button, Grid, Tab, Tabs, Typography, withStyles } from '@material-ui/core'
import AppointmentImage from '../../assests/images/Appointment.svg'
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import PendingActionsIcon from '@mui/icons-material/PendingActions'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import CancelIcon from '@mui/icons-material/Cancel'
import LoadingNew from '../../utilities/loading-page/LoadingNew'
import { getAppointmentListUpcomingPrevious } from '../../services/Appointments/appointments'
import moment from 'moment'
import { useSelector } from 'react-redux'
import { patientTimeZone } from '../../containers/AppointmentList/utils'
import { TIMEZONE_OPTONS } from '../../constants/timezones'
import { AppointmentTab } from './constant'

const CustomTab = withStyles((theme) => ({
    root: {
        minWidth: 0,
        padding: '0 8px',
        background: 'none',
        color: 'black',
        textTransform: 'capitalize',
        [theme.breakpoints.up('sm')]: {
            minWidth: 0,
        },
        '&.Mui-selected': {
            background: '#5571C6 !important',
            borderRadius: '5px',
            color: '#FFF',
            border: '1px solid #3F62C9',
        },
    },
    wrapper: {
        flexDirection: 'row',
        justifyContent: 'flex-start',
        height: '10px !important',
        fontFamily: 'Rubik',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '20px',
    },
}))((props) => <Tab {...props} />)

const AppointmentNew = () => {
    const [tabValue, setTabValue] = useState(AppointmentTab.UPCOMING)
    const [loading, setLoading] = useState(false)
    const [totalNoOfpage, setTotalNoOfpage] = useState(0)
    const [totalListOfAppintment, setTotalListOfAppointment] = useState(0)
    const [currentpage, setCurrentPage] = useState(1)
    const [appointmentList, setAppointmentList] = useState([])
    const scrollableContentRefAppointment = useRef(null)
    const [userTimeZone, setUserTimeZone] = useState('')
    const handleChangeTab = (e, value) => {
        if (!loading) {
            setTabValue(value)
        }
    }
    const loggedinPatiendata = useSelector((state) => state?.login?.userData)
    const timezone = () => {
        const Timezone = TIMEZONE_OPTONS?.find(
            (timezoneList) => timezoneList.key === loggedinPatiendata?.timezone,
        )?.abbr
        setUserTimeZone(Timezone)
    }
    const getAppointmentlist = async () => {
        try {
            setLoading(true)
            setAppointmentList([])
            const payload =
                tabValue === AppointmentTab.UPCOMING
                    ? {
                          page: 1,
                          size: 20,
                          status: 'booked,waitlist,pending',
                          start: moment().utc().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
                          _sort: 'date',
                      }
                    : {
                          page: 1,
                          size: 20,
                          status: 'fulfilled',
                          end: moment().utc().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
                          //   _sort: 'date',
                      }
            const res = await getAppointmentListUpcomingPrevious(payload)
            setCurrentPage(1)
            setAppointmentList(res?.data?.data?.data || [])
            setTotalListOfAppointment(res?.data?.data?.totalCount)
            const b = res?.data?.data?.totalCount / 20
            const remainder = res?.data?.data?.totalCount % 20
            let result
            if (remainder > 0) {
                result = Math.floor(b) + 1
            } else {
                result = Math.floor(b)
            }
            setTotalNoOfpage(result)
            setLoading(false)
        } catch (err) {
            console.log('err', err)
            setLoading(false)
        }
    }
    const handleScrollAppointment = async () => {
        if (totalNoOfpage > currentpage) {
            const scrollableContent = scrollableContentRefAppointment?.current
            const tolerance = 1
            const isAtEnd =
                scrollableContent?.scrollHeight - scrollableContent?.scrollTop <=
                scrollableContent?.clientHeight + tolerance
            if (isAtEnd) {
                try {
                    const nextPage = currentpage + 1
                    const payload =
                        tabValue === AppointmentTab.UPCOMING
                            ? {
                                  page: nextPage,
                                  size: 20,
                                  status: 'booked,waitlist,pending',
                                  start: moment().utc().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
                                  _sort: 'date',
                              }
                            : {
                                  page: nextPage,
                                  size: 20,
                                  status: 'fulfilled',
                                  end: moment().utc().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
                                  //   _sort: 'date',
                              }
                    const res = await getAppointmentListUpcomingPrevious(payload)
                    setCurrentPage(nextPage)
                    setAppointmentList([...appointmentList, ...res?.data?.data?.data])
                } catch (err) {
                    console.log('err', err)
                }
            }
        }
    }
    const appointmetStatus = (status) => {
        if (status === 'booked' || status === 'waitlist') {
            return {
                displayName: 'Confirmed',
                icon: (
                    <CheckCircleIcon
                        fontSize="14px"
                        style={{
                            paddingTop: '4px',
                            color: '#15A452',
                        }}
                    />
                ),
            }
        } else if (status === 'fulfilled') {
            return {
                displayName: 'Completed',
                icon: (
                    <CheckCircleIcon
                        fontSize="14px"
                        style={{
                            paddingTop: '4px',
                            color: '#5571C6',
                        }}
                    />
                ),
            }
        } else if (status === 'pending') {
            return {
                displayName: 'Pending',
                icon: (
                    <ErrorOutlineIcon
                        fontSize="14px"
                        style={{
                            paddingTop: '4px',
                            color: '#909EAE',
                        }}
                    />
                ),
            }
        }
        return {
            displayName: status,
            icon: (
                <ErrorOutlineIcon
                    fontSize="14px"
                    style={{
                        paddingTop: '4px',
                        color: '#909EAE',
                    }}
                />
            ),
        }
    }
    const checkThirtyMinBefore = (time) => {
        const minutesBefore = 30
        const givenDate = moment(time).tz(loggedinPatiendata?.timezone || 'US/Pacific')
        const currentTime = moment().tz(loggedinPatiendata?.timezone || 'US/Pacific')
        const timeBefore = givenDate.clone().subtract(minutesBefore, 'minutes')
        // const isBefore = givenDate.isBetween(timeBefore, currentTime)
        return currentTime >= timeBefore
    }
    useEffect(() => {
        getAppointmentlist()
    }, [tabValue])
    const intervalRef = useRef(null);


    // appoinetment list every 30sec
    useEffect(() => {
        if (intervalRef.current) {
            clearInterval(intervalRef.current);
        }
        intervalRef.current = setInterval(() => {
            getAppointmentlist()
        }, 30000); //30sec
        return () => {
            if (intervalRef.current) {
                clearInterval(intervalRef.current);
            }
        };
    })
    useEffect(() => {
        timezone()
    }, [loggedinPatiendata])

    return (
        <>
            <div className="dashboard_Home">
                <Grid container item spacing={1} xs={12} className="dashboard_Home_Struct">
                    <Grid item xs={3} className="leftSide_Dashboard">
                        <img
                            src={AppointmentImage}
                            alt=""
                            style={{ width: '271px', height: '284px' }}
                        />
                        <div>
                            <Typography>We are here to provide you with quality care.</Typography>
                        </div>
                        <div>
                            <span>
                                <Typography>If you need assistance call <span className="number">
                                    {loggedinPatiendata?.practice?.patient_support_number ||
                                    '(562) 268-0955'}
                                </span></Typography>
                                
                            </span>
                        </div>
                    </Grid>
                    <Grid item xs={9} className="rightSide_Dashboard">
                        <Grid item className="main-card">
                            <Typography className="mainText">Appointments</Typography>
                            {tabValue === AppointmentTab.UPCOMING ? (
                                <Typography className="sub-mainText">
                                    See all of your confirmed appointments below.{' '}
                                </Typography>
                            ) : (
                                <Typography className="sub-mainText">
                                    See all of your completed appointments below.{' '}
                                </Typography>
                            )}
                            <div className="tabClasses view_tab_parent">
                                <Tabs
                                    style={{
                                        width: '165px',
                                        borderRadius: '5px',
                                        background: '#EEF6FE',
                                    }}
                                    value={tabValue}
                                    onChange={handleChangeTab}
                                    TabIndicatorProps={{ style: { display: 'none' } }}>
                                    <CustomTab value={0} label="Upcoming" size="small" />
                                    <CustomTab value={1} label="Previous" size="small" />
                                </Tabs>
                            </div>
                            <div style={{ position: 'relative' }}>
                                <LoadingNew visible={loading} />
                                {tabValue === AppointmentTab.UPCOMING && (
                                    <Typography className="no-of-appointment-text">
                                        {totalListOfAppintment} upcoming{' '}
                                        {totalListOfAppintment > 1 ? 'appointments' : 'appointment'}
                                    </Typography>
                                )}
                                <Grid
                                    item
                                    style={{ paddingRight: '30px', marginTop: '10px' }}
                                    ref={(ref) => (scrollableContentRefAppointment.current = ref)}
                                    onScroll={handleScrollAppointment}
                                    className="appointmentListingBox">
                                    {appointmentList.length > 0 &&
                                        appointmentList.map(
                                            (appointmentItem, appointmentItemIndex) => {
                                                const status = appointmetStatus(
                                                    appointmentItem?.status,
                                                )
                                                return (
                                                    <Grid
                                                        container
                                                        className="datagridbox"
                                                        key={appointmentItemIndex}
                                                        style={{
                                                            marginBottom: '15px',
                                                            width: '100%',
                                                        }}>
                                                        <div
                                                            style={{
                                                                borderRight: '1px solid #BFBFBF',
                                                                width: '7%',
                                                            }}>
                                                            <Typography className="year-month-text">
                                                                {patientTimeZone(
                                                                    appointmentItem?.start,
                                                                    loggedinPatiendata?.timezone,
                                                                ).format('MMM')}
                                                            </Typography>
                                                            <Typography
                                                                className="year-month-text"
                                                                style={{
                                                                    fontSize: '24px',
                                                                    marginTop: '7px',
                                                                }}>
                                                                {patientTimeZone(
                                                                    appointmentItem?.start,
                                                                    loggedinPatiendata?.timezone,
                                                                ).format('DD')}
                                                            </Typography>
                                                        </div>
                                                        <div
                                                            style={{
                                                                paddingLeft: '30px',
                                                                width: '20%',
                                                            }}>
                                                            <Typography className="drName">
                                                                {appointmentItem?.practitioner_first_name +
                                                                    ' ' +
                                                                    appointmentItem?.practitioner_last_name}
                                                            </Typography>
                                                            <Typography
                                                                className="noteType"
                                                                style={{ marginTop: '7px' }}>
                                                                {
                                                                    appointmentItem?.specialty
                                                                        ?.specialty
                                                                }
                                                            </Typography>
                                                        </div>
                                                        <div
                                                            style={{
                                                                paddingLeft: '10px',
                                                                width: '30%',
                                                            }}>
                                                            <Grid container>
                                                                <AccessTimeIcon
                                                                    color="#667689"
                                                                    fontSize="14px"
                                                                    style={{ paddingTop: '4px' }}
                                                                />
                                                                <Typography className="time">
                                                                    {patientTimeZone(
                                                                        appointmentItem?.start,
                                                                        loggedinPatiendata?.timezone,
                                                                    ).format('h:mm')}
                                                                    <span
                                                                        style={{
                                                                            fontSize: '12px',
                                                                            paddingLeft: '5px',
                                                                            paddingRight: '1px',
                                                                        }}>
                                                                        {patientTimeZone(
                                                                            appointmentItem?.start,
                                                                            loggedinPatiendata?.timezone,
                                                                        ).format('A')}
                                                                    </span>
                                                                    -{' '}
                                                                    {patientTimeZone(
                                                                        appointmentItem?.end,
                                                                        loggedinPatiendata?.timezone,
                                                                    ).format('h:mm')}
                                                                    <span
                                                                        style={{
                                                                            fontSize: '12px',
                                                                            paddingLeft: '5px',
                                                                            paddingRight: '5px',
                                                                        }}>
                                                                        {patientTimeZone(
                                                                            appointmentItem?.end,
                                                                            loggedinPatiendata?.timezone,
                                                                        ).format('A')}
                                                                    </span>
                                                                    {userTimeZone || 'PST'}
                                                                </Typography>
                                                            </Grid>
                                                            <Grid
                                                                container
                                                                style={{ marginTop: '7px' }}>
                                                                <LocationOnIcon
                                                                    color="#667689"
                                                                    fontSize="12px"
                                                                    style={{ paddingTop: '4px' }}
                                                                />
                                                                <Typography className="time">
                                                                    {appointmentItem?.location_name}
                                                                </Typography>
                                                            </Grid>
                                                        </div>
                                                        <div
                                                            style={{
                                                                paddingLeft: '0px',
                                                                width: '20%',
                                                            }}>
                                                            <Grid container>
                                                                {status.icon}
                                                                <Typography className="confirmText">
                                                                    {status.displayName}
                                                                </Typography>
                                                            </Grid>
                                                        </div>
                                                        <div
                                                            style={{
                                                                paddingLeft: '0px',
                                                                width: '10%',
                                                            }}>
                                                            {tabValue === AppointmentTab.UPCOMING &&
                                                                checkThirtyMinBefore(
                                                                    appointmentItem?.start,
                                                                ) && (
                                                                    <Button className="lunchVisitButton">
                                                                        Launch visit
                                                                    </Button>
                                                                )}
                                                        </div>
                                                    </Grid>
                                                )
                                            },
                                        )}
                                    {appointmentList.length === 0 &&
                                        !loading &&
                                        tabValue === AppointmentTab.PREVIOUS && (
                                            <Typography className="no-of-appointment-text">
                                                No record found
                                            </Typography>
                                        )}
                                </Grid>
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        </>
    )
}

export default AppointmentNew
