import { objectReversal } from "../../shared/utils"

export const headerTabConstantValues = {
    0: 'dashboard',
    1: 'patient-book-appointment',
    2: 'message-care-team',
    3: 'my-health-record',
    4: 'appointments',
}

export const reversedHeaderConstants = objectReversal(headerTabConstantValues)