import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles'
import { Amplify } from 'aws-amplify'
import React from 'react'
import ReactDOM from 'react-dom'
import * as Sentry from "@sentry/react";
import { QueryClient, QueryClientProvider } from 'react-query'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import Theme from '../src/styles/themes/theme'
import awsExports from './aws-exports'
import AppStateProvider from './components/Consultation/state'
import { SnackbarProvider } from 'notistack'
import { AppContainer } from './hoc'
import './index.scss'
import store from './store'
Amplify.configure(awsExports)

const isProduction = process.env.REACT_APP_ENV === 'prod'
const sentryEnvironment = window.location.hostname === 'localhost' ? 'local' : process.env.REACT_APP_TEST_ENV

Sentry.init({
    dsn: "https://a003fe2661175c0f6df68a4cbcc0d856@o4507780380033024.ingest.us.sentry.io/4507780382523392",
    tracesSampleRate: isProduction ? 0.1 : 1.0, 
    replaysOnErrorSampleRate: isProduction ? 0.1 : 1.0,
    environment: sentryEnvironment,
    integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration(),
    ],
});

// Create a client
const queryClient = new QueryClient()
//Manual Middle Ware code

const App = (
    <QueryClientProvider client={queryClient}>
        <Provider store={store}>
            <AppStateProvider>
                <MuiThemeProvider theme={Theme}>
                    <SnackbarProvider
                        maxSnack={3}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}>
                        <BrowserRouter>
                            <AppContainer></AppContainer>
                        </BrowserRouter>
                    </SnackbarProvider>
                </MuiThemeProvider>
            </AppStateProvider>
        </Provider>
    </QueryClientProvider>
)

ReactDOM.render(App, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();