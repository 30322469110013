import { Grid, Tabs, Tab, Typography, Button } from '@material-ui/core'
import React, { useState, useEffect, useMemo, useRef } from 'react'
import { withStyles } from '@material-ui/core/styles'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import './AppointmentDetails.scss'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment-timezone'
import { makeStyles } from '@material-ui/styles'
import { TIMEZONE_OPTONS } from '../../constants/timezones'
import { date } from 'yup'
import { getAppointmentListUpcomingPrevious } from '../../services/Appointments/appointments'
import { confirmedAppointmentStart } from '../../store/actions/appointments'
import { useHistory } from 'react-router-dom'
import { me } from '../../services/Login/login'
import { changeHeaderTab } from '../../store/actions/header'
import { reversedHeaderConstants } from '../Header/constants'
const useStyles = makeStyles(() => ({
    disabledButton: {
        backgroundColor: '#C0C1C5 !important',
        color: 'rgba(255, 255, 255, 0.7) !important',
    },
}))

const AppointmentDetails = () => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const history = useHistory()
    const loggedinPatiendataOld = useSelector((state) => state?.login?.userData)
    const [loggedinPatiendata, setLoggedinPatiendata] = useState(loggedinPatiendataOld)
    // const [startTime, setStatrttime] = useState('')

    // const toggleOpen = () => {
    //     setOpen(true)
    //     setCurrentTab(0)
    // }
    const memorizing = useMemo(() => loggedinPatiendata, [loggedinPatiendata])

    const [appointmentTime, setAppointmentTime] = React.useState('')
    const appointmentConfirmedList = async () => {
        // const payload = {
        //     page: 1,
        //     size: 1,
        //     status: 'booked,waitlist',
        //     start: moment().utc().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
        //     _sort: 'date',
        // }
        // const res = await getAppointmentListUpcomingPrevious(payload)
        // const value = res?.data?.data?.data.length ? res?.data?.data?.data[0].start : ''
        // setStatrttime(value)
        // dispatch(confirmedAppointmentStart(value))
        const data = await me()
        setLoggedinPatiendata(data?.data?.data)
    }
    const formatTimeZone = () => {
        if (loggedinPatiendata?.next_appointment?.start) {
        // if (startTime) {
            const utcTime = moment.utc(loggedinPatiendata?.next_appointment?.start),
                userTimezone = TIMEZONE_OPTONS?.find(
                    (timezoneList) =>
                        timezoneList.key ===
                        (loggedinPatiendata?.timezone || loggedinPatiendata?.data?.timezone),
                )?.abbr

            const pstTime = utcTime.tz(memorizing?.timezone || 'US/Pacific')
            const formattedTime = pstTime.format(
                `MMMM Do, YYYY [at] h:mma [${userTimezone || 'PST'}]`,
            )

            return formattedTime?.toString()?.split('at')
        }
        return ''
    }

    const launchEncounter = () => {
        window.open(loggedinPatiendata?.next_appointment?.link, '_blank')
    }

    React.useEffect(() => {
        setAppointmentTime(formatTimeZone())
    }, [loggedinPatiendata])
    React.useEffect(() => {
        appointmentConfirmedList()
    }, [])

    const intervalRef = useRef(null);


    // appoinetment list every 30sec
    useEffect(() => {
        if (intervalRef.current) {
            clearInterval(intervalRef.current);
        }
        intervalRef.current = setInterval(() => {
            appointmentConfirmedList()
        }, 30000); //30sec
        return () => {
            if (intervalRef.current) {
                clearInterval(intervalRef.current);
            }
        };
    })

    const Banner = () => (
        <div
            style={{
                // marginRight: '2rem',
                display: 'flex',
                alignItems: 'baseline',
            }}>
            <span className="appointmentTextStart">
                If you are in crisis or having an emergency, please call 911 | If you need any assistance please call us at{' '}
                <span
                    style={{
                        fontWeight: '500',
                    }}>
                    {}
                    {loggedinPatiendata?.practice?.patient_support_number || '(562) 268-0955'}
                </span>
            </span>
        </div>
    )
    const minuteDiff = (stat, date1, date2) => {
        const res = moment.utc(date2).diff(date1, 'minutes')

        return Number(res + 1)
    }

    const isDisabled = () => {
        const c =
            minuteDiff(
                'first',
                moment().utc(),
                loggedinPatiendata?.next_appointment?.start ||
                    loggedinPatiendata?.data?.next_appointment?.start,
            ) > 10 ||
            minuteDiff(
                'sec',
                moment().utc(),
                loggedinPatiendata?.next_appointment?.end ||
                    loggedinPatiendata?.data?.next_appointment?.end,
            ) < 0

        return c
    }
    const shouldShowLaunchButton = () => {
        return (
            minuteDiff(
                'shouldShow',
                moment(new Date()).utc(),
                loggedinPatiendata?.next_appointment?.end ||
                    loggedinPatiendata?.data?.next_appointment?.end,
            ) >= 0
        )
    }

    const travelToAppt = () => {
        if (history?.location?.pathname !== '/patient-book-appointment') {
            dispatch(changeHeaderTab(reversedHeaderConstants['patient-book-appointment']))
            history.push('/patient-book-appointment')
        }
    }
    return (
        <>
            <div
                className="app_appointmentdeatis_header"
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                }}>
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                    }}>
                    {(
                    (loggedinPatiendata?.next_appointment?.start ||
                    loggedinPatiendata?.data?.next_appointment?.start) &&
                    shouldShowLaunchButton() &&
                    appointmentTime[0] )? (
                        <>
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'baseline',
                                }}>
                                <span className="appointmentTextStart">
                                    You have an upcoming appointment on
                                </span>
                                <span className="appointmentText">{appointmentTime[0]}</span>
                                <span className="appointmentTextStart"> at</span>
                                <span className="appointmentText">{appointmentTime[1]}.</span>
                            </div>

                            {shouldShowLaunchButton() && (
                                <Button
                                    onClick={launchEncounter}
                                    classes={{ disabled: classes.disabledButton }}
                                    disabled={isDisabled()}
                                    className="launchButton">
                                    Launch visit
                                </Button>
                            )}
                        </>
                    ) : (
                        <>
                            <span className="appointmentTextStart">
                                You have no upcoming appointments,{' '}
                                <a
                                    className="appointmentTextStart book-now"
                                    style={{ color: 'white' }}
                                    onClick={travelToAppt}>
                                    Book Now!
                                </a>
                            </span>
                        </>
                    )}
                </div>

                <Banner />
            </div>
        </>
    )
}

export default AppointmentDetails
